<template>
    <tr @click="navigateToReview" class="transition duration-200 cursor-pointer hover:bg-scorelitorange">
        <td v-if="review.trainee" class="px-6 py-3 whitespace-nowrap">
            <div class="flex items-center">
                <div v-if="review.trainee.profile_image" class="flex-shrink-0 h-10 w-10">
                    <img class="h-10 w-10 rounded-full border-white border-2 object-cover" :src="review.trainee.profile_image" alt="">
                </div>
                <div class="ml-4">
                    <div class="text-sm leading-5 font-medium text-white font-medium">
                        {{ review.trainee.name }}
                    </div>
                </div>
            </div>
        </td>
        <td v-else-if="review.team">
            <div class="flex items-center">
                <div class="ml-4">
                    <div class="text-sm leading-5 font-medium text-white font-medium">
                        {{ review.team.name }}
                    </div>
                </div>
            </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-white font-medium">
            {{ review.match.video.title }}
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-white font-medium">
            {{ review.product.name }}
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-white">
             {{ dateToHumanReadable(review.created_at) }}
        </td>
        <td class="px-6 py-4 whitespace-nowrap float-right flex items-center">
            <span class="px-3 py-2 text-xs font-medium rounded" :class="status_pill_classes">
                {{ status_text }}
            </span>
        </td>
    </tr>
</template>

<script>
import { status } from '@/helper/models/Review';
import { dateToHumanReadable } from '@/helper/javascript/HumanReadableDate';
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    props: ['review'],
    computed: {
        status: function (){
            return status(this.review);
        },
        status_text: function (){
            switch(this.status){
                case 'finished':
                    return $t('history.status.finished');
                
                case 'in_progress':
                    return $t('history.status.in_progress');
                
                default:
                    return $t('history.status.new');
            }
        },
        status_pill_classes: function(){
            switch(this.status){
                case 'finished':
                    return 'bg-scorelitgreen text-white';
                
                case 'in_progress':
                    return 'bg-scorelitred text-white';
                
                default:
                    return 'border text-white';
            }
        },
    },
    methods: {
        navigateToReview() {
            this.$router.push({name: 'review', params: {id: this.review.id}})
        },
        dateToHumanReadable,
    },
}
</script>