<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 uppercase text-xl font-extrabold">
                    <i18n-t keypath="history.title" />
                </h2>
                <div class="flex flex-col">
                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                <i18n-t keypath="history.item.trainee" />
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                <i18n-t keypath="history.item.match" />
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                <i18n-t keypath="history.item.product" />
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                <i18n-t keypath="history.item.created_at" />
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider float-right">
                                                <i18n-t keypath="history.status.title" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-500">
                                        <template v-if="reviewItems.length !== 0">
                                            <ReviewRow v-for="review in reviewItems" :key="review.id" :review="review" class="" />
                                        </template>
                                        <tr v-else class="cursor-pointer hover:bg-scorelitorange transition duration-200">
                                            <td colspan="5" class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-300 font-medium text-center">
                                                <i18n-t keypath="history.empty" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/wrappers/Dashboard";
import { indexReview } from "@/helper/api/ReviewHelper";
import ReviewRow from "@/components/ReviewRow";

export default {
    name: "ReviewHistory",
    components: {DashboardWrapper, ReviewRow},
    data() {
        return {
            reviewItems: [],
            isModalOpen: false,
            dont_show_again: null,
        }
    },
    created() {
        this.getReviewItems();
    },
    methods: {
        getReviewItems() {
            indexReview()
                .then(reviews => {
                    this.reviewItems = reviews;
                })
                .catch(err => {
                    console.log(err);
                });
        },
    }
}
</script>

<style scoped>

</style>
