function dateToHumanReadable(time_stamp){
    if(!time_stamp) return '-';

    const date = new Date(time_stamp);
    const now = new Date();

    let str = date.getDate() + "-" + (date.getMonth() + 1);

    if(date.getFullYear() !== now.getFullYear()){
        str += "-" + date.getFullYear();
    }

    str += " " + date.getHours() + ":" + `${date.getMinutes()}`.padStart(2, '0');

    return str;
}

export { dateToHumanReadable };